import page1 from '../assets/images/new/gallery_modal/1.png';
import page12 from '../assets/images/new/gallery_modal/12.png';
import page13 from '../assets/images/new/gallery_modal/13.png';
import page14 from '../assets/images/new/gallery_modal/14.png';
import page15 from '../assets/images/new/gallery_modal/15.png';
import page16 from '../assets/images/new/gallery_modal/16.png';
import page17 from '../assets/images/new/gallery_modal/17.png';
import page18 from '../assets/images/new/gallery_modal/18.png';
import page19 from '../assets/images/new/gallery_modal/19.png';
import page110 from '../assets/images/new/gallery_modal/110.png';
import page111 from '../assets/images/new/gallery_modal/111.png';
import page112 from '../assets/images/new/gallery_modal/112.png';
import page113 from '../assets/images/new/gallery_modal/113.png';
import page114 from '../assets/images/new/gallery_modal/114.png';
import page115 from '../assets/images/new/gallery_modal/115.png';
import page116 from '../assets/images/new/gallery_modal/116.png';
import page117 from '../assets/images/new/gallery_modal/117.png';
import page118 from '../assets/images/new/gallery_modal/118.png';
import page119 from '../assets/images/new/gallery_modal/119.png';
import page120 from '../assets/images/new/gallery_modal/120.png';
import page121 from '../assets/images/new/gallery_modal/121.png';
import page122 from '../assets/images/new/gallery_modal/122.png';
import page123 from '../assets/images/new/gallery_modal/123.png';
import page124 from '../assets/images/new/gallery_modal/124.png';
import page125 from '../assets/images/new/gallery_modal/125.png';
import page126 from '../assets/images/new/gallery_modal/126.png';
import page127 from '../assets/images/new/gallery_modal/127.png';
import page128 from '../assets/images/new/gallery_modal/128.png';
import page129 from '../assets/images/new/gallery_modal/129.png';
import page130 from '../assets/images/new/gallery_modal/130.png';
import page131 from '../assets/images/new/gallery_modal/131.png';
import page132 from '../assets/images/new/gallery_modal/132.png';
import page133 from '../assets/images/new/gallery_modal/133.png';
import page134 from '../assets/images/new/gallery_modal/134.png';
import page135 from '../assets/images/new/gallery_modal/135.png';
import page136 from '../assets/images/new/gallery_modal/136.png';
import page137 from '../assets/images/new/gallery_modal/137.png';
import page138 from '../assets/images/new/gallery_modal/138.png';
import page139 from '../assets/images/new/gallery_modal/139.png';
import page140 from '../assets/images/new/gallery_modal/140.png';
import page141 from '../assets/images/new/gallery_modal/141.png';
import page142 from '../assets/images/new/gallery_modal/142.png';
import page143 from '../assets/images/new/gallery_modal/143.png';
import page144 from '../assets/images/new/gallery_modal/144.png';
import page145 from '../assets/images/new/gallery_modal/145.png';
import page146 from '../assets/images/new/gallery_modal/146.png';
import page147 from '../assets/images/new/gallery_modal/147.png';
import page148 from '../assets/images/new/gallery_modal/148.png';
import page149 from '../assets/images/new/gallery_modal/149.png';
import page150 from '../assets/images/new/gallery_modal/150.png';
import page151 from '../assets/images/new/gallery_modal/151.png';
import page152 from '../assets/images/new/gallery_modal/152.png';
import page153 from '../assets/images/new/gallery_modal/153.png';
import page154 from '../assets/images/new/gallery_modal/154.png';
import page155 from '../assets/images/new/gallery_modal/155.png';
import page156 from '../assets/images/new/gallery_modal/156.png';
import page157 from '../assets/images/new/gallery_modal/157.png';
import page158 from '../assets/images/new/gallery_modal/158.png';
import page159 from '../assets/images/new/gallery_modal/159.png';
import page160 from '../assets/images/new/gallery_modal/160.png';
import page161 from '../assets/images/new/gallery_modal/161.png';
import page162 from '../assets/images/new/gallery_modal/162.png';
import page163 from '../assets/images/new/gallery_modal/163.png';
import page164 from '../assets/images/new/gallery_modal/164.png';
import page165 from '../assets/images/new/gallery_modal/165.png';
import page166 from '../assets/images/new/gallery_modal/166.png';
import page167 from '../assets/images/new/gallery_modal/167.png';
import page168 from '../assets/images/new/gallery_modal/168.png';
import page169 from '../assets/images/new/gallery_modal/169.png';
import page170 from '../assets/images/new/gallery_modal/170.png';
import page171 from '../assets/images/new/gallery_modal/171.png';
import page172 from '../assets/images/new/gallery_modal/172.png';
import page173 from '../assets/images/new/gallery_modal/173.png';
import page174 from '../assets/images/new/gallery_modal/174.png';
import page175 from '../assets/images/new/gallery_modal/175.png';
import page176 from '../assets/images/new/gallery_modal/176.png';
import page177 from '../assets/images/new/gallery_modal/177.png';
import page178 from '../assets/images/new/gallery_modal/178.png';
import page179 from '../assets/images/new/gallery_modal/179.png';
import page180 from '../assets/images/new/gallery_modal/180.png';
import page181 from '../assets/images/new/gallery_modal/181.png';
import page182 from '../assets/images/new/gallery_modal/182.png';
import page183 from '../assets/images/new/gallery_modal/183.png';
import page184 from '../assets/images/new/gallery_modal/184.png';
import page185 from '../assets/images/new/gallery_modal/185.png';
import page186 from '../assets/images/new/gallery_modal/186.png';
import page187 from '../assets/images/new/gallery_modal/187.png';
import page188 from '../assets/images/new/gallery_modal/188.png';
import page189 from '../assets/images/new/gallery_modal/189.png';
import page190 from '../assets/images/new/gallery_modal/190.png';
import page191 from '../assets/images/new/gallery_modal/191.png';
import page192 from '../assets/images/new/gallery_modal/192.png';

const GalleryPage = () => {
    return ( 
        <div>
            <div className='px-4 md:px-16 pb-4 md:pb-16 image-gallery'>
                <img src={page1}/>
                <img src={page12}/>
                <img src={page13}/>
                <img src={page14}/>
                <img src={page15}/>
                <img src={page16}/>
                <img src={page17}/>
                <img src={page18}/>
                <img src={page19}/>
                <img src={page110}/>
                <img src={page111} />
                <img src={page112} />
                <img src={page113} />
                <img src={page114} />
                <img src={page115} />
                <img src={page116} />
                <img src={page117} />
                <img src={page118} />
                <img src={page119} />
                <img src={page120} />
                <img src={page121} />
                <img src={page122} />
                <img src={page123} />
                <img src={page124} />
                <img src={page125} />
                <img src={page126} />
                <img src={page127} />
                <img src={page128} />
                <img src={page129} />
                <img src={page130} />
                <img src={page131} />
                <img src={page132} />
                <img src={page133} />
                <img src={page134} />
                <img src={page135} />
                <img src={page136} />
                <img src={page137} />
                <img src={page138} />
                <img src={page139} />
                <img src={page140} />
                <img src={page141} />
                <img src={page142} />
                <img src={page143} />
                <img src={page144} />
                <img src={page145} />
                <img src={page146} />
                <img src={page147} />
                <img src={page148} />
                <img src={page149} />
                <img src={page150} />
                <img src={page151} />
                <img src={page152} />
                <img src={page153} />
                <img src={page154} />
                <img src={page155} />
                <img src={page156} />
                <img src={page157} />
                <img src={page158} />
                <img src={page159} />
                <img src={page160} />
                <img src={page161} />
                <img src={page162} />
                <img src={page163} />
                <img src={page164} />
                <img src={page165} />
                <img src={page166} />
                <img src={page167} />
                <img src={page168} />
                <img src={page169} />
                <img src={page170} />
                <img src={page171} />
                <img src={page172} />
                <img src={page173} />
                <img src={page174} />
                <img src={page175} />
                <img src={page176} />
                <img src={page177} />
                <img src={page178} />
                <img src={page179} />
                <img src={page180} />
                <img src={page181} />
                <img src={page182} />
                <img src={page183} />
                <img src={page184} />
                <img src={page185} />
                <img src={page186} />
                <img src={page187} />
                <img src={page188} />
                <img src={page189} />
                <img src={page190} />
                <img src={page191} />
                <img src={page192} />
            </div>
        </div>
     );
}
 
export default GalleryPage;